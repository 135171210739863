@font-face {
  font-family: "Space Mono";
  src: url("../public/fonts/SpaceMono.ttf");
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../public/fonts/SpaceGrotesk.ttf");
}

body {
  overscroll-behavior: none;
}

/* slider input */

.slider-track {
  background: transparent !important;
  width: 100% !important;
  right: 0 !important;
  height: 100% !important;
}

.slider-thumb {
  background-color: #1c1814 !important;
  border-radius: 100px;
  height: 20px !important;
  width: 20px !important;
  outline: 0 !important;
  text-align: center;
  color: white;
  font-size: 10px;
  padding: 3px 5px 4px 5px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: grab;
}

@media screen and (max-width: 1023px) {
  .slider-thumb {
    height: 30px !important;
    width: 30px !important;
    padding: 6px;
    font-size: 12px;
  }
}

/* ------ */

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #1c1814;
}
